@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/GraphikRegular.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/GraphikBold.otf') format('opentype');
}

body {
  color: #000;
  font-family: Graphik !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 125% */
  letter-spacing: 0.01563rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Login Module  */
.height-full {
  height: 100vh;
}

.login-left-align {
  /* background: #64BE49; */
  /* background-image: url('../public/images/bg-noise-texture.svg'); */
  flex: 1 1 60%;
  background-size: cover;
  margin-right: 0 !important;
  background-position: center center;
}

.login-right-form {
  /* background-image: url('../public/images/login-right.svg'); */
  background-color: #FEFFFFD9;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
  max-width: calc(100% - 60%);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-left: 1px solid #f3f2f1d9;
}

.left-bottom-text {
  padding-bottom: 4rem;
  color: #F0F0F0;
}

.left-intro {
  padding-bottom: 1rem;
  font-weight: 300;
  font-size: 26px;
  color: #196CE9;
}

.left-intro-second {
  font-weight: 600;
  font-size: 24px;
  max-width: 600px;
}

.login-footer-text,
.left-intro-second {
  color: #2D2E35;
}

.login-footer-text {
  font-size: 12px;
  margin-top: 1rem;
}

.left-image-container {
  padding: 2rem 2rem 2rem 2rem;
  height: 100vh;
}

.left-image {
  max-width: 400px !important;
  padding: 0;
  /* margin: 2rem;
 max-height: 90vh !important; */
}

.padding-text {
  padding: 2rem 2rem 2rem 0;

}

.sso-text {
  font-weight: 600;
  font-size: 24px;
  max-width: 307px;
}

.login-right-flex {
  height: 100vh;
}

.login-button,
.next-button,
.modal-submit-btn,
.modal-cancel-btn,
.login-button:hover,
.next-button:hover,
.modal-submit-btn:hover,
.modal-cancel-btn:hover {
  background: #FFC20E;
  border-radius: 2rem;
  border: none;
  padding: 1rem;
  height: auto;
  color: #515151;
  font-weight: 600;
  font-size: 1rem;
}

.login-button:hover,
.next-button:hover,
.modal-submit-btn:hover {
  opacity: 0.8;
}

.login-button:focus-visible,
.next-button:focus-visible
{
  outline: 2px solid #ffeb3b;
  outline-offset: '2px';
  transition: opacity .2s ease-in-out;
}

.next-button {
  top: 70px;
}

.login-container {
  height: 100vh;
  flex-direction: column;
  margin-right: 0 !important;
  flex-wrap: wrap;
}

.welcome-text {
  padding-left: 1rem;
  font-weight: 600;
  font-size: 30px;
}

/* Ends */

/* Left Menu Top */
.left-menu {
  background: #FAFAFA;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  width: 230px;
  height: 100%;
  position: fixed;
  color: #000000;
  top: 0;
  right: -230px;
  z-index: 999;
  transition: right 0.4s ease;
}

.left-menu.show-menu {
  right: 0;
}

.left-menu-top {
  background: #FAFAFA;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  width: 230px;
  height: 100px;
  position: fixed;
  color: #000000;
}

.left-menu-top-list {
  padding-top: 16px !important;
}

.left-menu-top-list img {
  height: 4rem;
}

.left-menu-list-wrapper {
  /* padding-top: 4rem; */
}

.left-menu-menu-list li.ui-list__item {
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
}

.left-menu-menu-list li.ui-list__item:hover {
  background: none !important;
  color: #2D2E35;
}

.left-menu-bottom {
  bottom: 0;
  position: fixed;
  width: 230px;
}

.menu-icon {
  width: 1.5rem !important;
}

.profile-icon {
  width: 2.5 rem !important;
}

.app-title {
  color: #ffc20e;
}

.profile-top-icon {
  border-bottom-right-radius: 0 !important;
  width: 130px !important;
}

.left-menu-menu-list li[aria-selected=true] {
  background: none !important;
}

.left-menu-menu-list li:hover a,
.left-menu-menu-list li[aria-selected=true] a {
  color: #196CE9 !important;
}

.left-menu-menu-list li a {
  text-decoration: none;
  color: #2D2E35;
}

.chat-image {
  background-image: './images/fi-message-square-hover.svg';

}

.initials-text {
  width: 32px;
  display: flex;
  height: 32px;
  border: 1px solid #027CAB;
  border-radius: 2rem;
  background-color: #00ADEF;
  color: #027CAB;
  align-items: center;
  justify-content: center;
}

.profile-info .ui-text,
.profile-info .ui-list__itemheader,
.profile-info .ui-list__itemcontent {
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-info .ui-list__itemcontent .ui-text
{
  display: block;
}

.profile-info  .ui-list__item__main {
  min-width: 85%;
}

.ui-chat__messagecontent {
  padding-bottom: 1.25rem;
}

.ui-chat-user .ui-chat__messagecontent {
  padding-bottom: 0.5rem;
}

/* End */

/* src/App.css */

/* Login CSS */


/* End */

/* Top header */
.top-card .ui-card,
.top-card .ui-card:hover {
  background: #FEFFFFD9;
  color: #2D2E35;
  /* min-height: 125px; */
  border-radius: 2rem;
  margin: 0.5rem 0;
  /* width: 14rem; */
  padding: 0.5rem 0.5rem;
}

.main-container {
  padding-top: 6rem;
  display: flex;
}

.footer-container {
  padding-top: 0.5rem;
  display: flex;
}

.top-card,
.footer-left {
  flex-flow: column;
  width: 20vw;
  flex-flow: wrap;
  display: flex;
}

.card-text {
  min-width: 115px;
}

.top-card .ui-card {
  /* flex: 0 0 auto; */
}

.ui-reactions {
  bottom: 0;
  position: absolute;
  right: 1rem;
  display: flex;
  padding-bottom: 0.25rem;
}

.align-Content {
  align-content: center;
}

.ui-reaction__icon svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #0b57d0;
}


.top-card .ui-card__body {
  cursor: pointer;
  max-height: 40px; /* Need to check this when we have more lines */
}

.hide {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

/* Top header end */

.ui-menu {
  width: 100%;
}

.menu-list {
  padding: 1.625rem 5.125rem 1.75rem 1.5rem;
  align-items: center;
  width: 100% !important;
  background: #FAFAFA !important;
  border: none !important;
  padding: 0;
}

.menu-list li {
  width: 100% !important;
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
  height: 45px;
}

.menu-list li:hover {
  background: #BBBBBB !important;
}

.menu-list li a {
  padding-top: 15px;
  padding-bottom: 15px;
}

.chat-text-field {
  flex-grow: 1;
  /* width: 57.8125rem;
  height: 3.9375rem;
  flex-shrink: 0; */
}

.chat-provider .is-active,
.chat-provider .chat-text-field input::after {
  border: none !important;
}

.chat-provider {
  width: 75vw
}

.chat-text-field .ms-TextField-wrapper:focus-visible,
.chat-text-field .ms-TextField-fieldGroup,
.chat-text-field .ms-TextField-fieldGroup:focus-visible,
.chat-text-field .ms-TextField-fieldGroup::after {
  border: none !important;
}

.chat-text-field .ms-TextField-fieldGroup {
  border: none;
  height: 42px;
}

.chat-text-field input {
  border-radius: 1.0625rem;
  background: #F2F2F2;
  /* width: 80vw;
  height: 2.9375rem;
  flex-shrink: 0; */
}

.ui-chat-modal .ui-chat__item__message,
.ui-chat-user .ui-chat__item__message {
  
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  max-width: 80%;
}

.no-response .ui-chat__item__message,
.no-response .ui-chat__message {
  width: 80%;
  position: inherit;
}

.ui-chat-modal .ui-chat__item__message {
  border-radius: 1.1875rem 1.1875rem 1.1875rem 0rem;
  background: #F0F0F0;
}

.ui-chat-user .ui-chat__item__message {
  border-radius: 1.1875rem 1.1875rem 0rem 1.1875rem;
  background: #EBF3FF;
  float: right;
}

.profile-icon {
  margin: 1.67em 0;
  width: 4.3125rem;
  height: 4.3125rem;
}

.ui-chat-modal .ui-chat__item__message p,
.ui-chat-user .ui-chat__item__message p {
  margin: 0;
}

.chat-no-image {
  width: 1.5rem;
  height: 1.5rem;
  fill: #FFF;
  stroke-width: 1.6px;
  stroke: #BBB;
}

.chat-no-image img {
  border: none;
}

.ui-chat-modal .ui-box {
  margin-top: 0 !important;
  bottom: 0;
}

.ui-chat__item {
  padding-top: 1rem;
}

.ui-chat-spinner .ui-chat__item__message {
  margin-top: 0.925rem !important;
  margin-left: 4.5rem;
}

.ui-chat {
  border: none !important;
}

.ui-chat__message,
.ui-chat {
  background: none !important;
  min-width: 6.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.4375rem;
}

.ui-chat__message {
  margin-right: 0 !important;
  margin-left: 0 !important;
  max-width: fit-content !important;
}


.app-container {
  height: 100vh;
  /* background-image: url('../public/images/bg-noise-texture.svg'); */
  background-size: cover;
  background-position: center center;
}

.chat-messages {
  height: calc(100vh - 300px);
  margin-bottom: 10px;
  overflow: auto;
}

.right-topcontainer,
.footer-content {
  width: 75vw;
  display: flex;
  margin-left: 1rem;
}

.right-topcontainer {
  background: #FFFBFE;
  border-radius: 1rem;
}

.footer-content {
  justify-content: center;
  color: #858585;
}

.footer-comp-title {
  text-decoration: underline;
}

.right-topcontainer::before {
  background-image: url('../public/images/fluke-logo.svg');
  content: "";
  position: absolute;
  top: calc(50% - 75px);
  left: calc(100%/2);
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  background-position: center center;
  opacity: 0.3;
}

/* Apply custom styles to the scrollbar */
.chat-container ul {
  overflow-y: auto;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #797979 #FAFAFA;
  /* Firefox */
}

.question-title {
  color: #196CE9;
}

.question-link
{
  border: 1px solid #196CE9;
  padding: 0.5rem;
  border-radius: 1.1875rem 1.1875rem 0rem 1.1875rem;
  color: #2D2E35 !important;
}

.question-link,
.question-link:focus {
  text-decoration: none !important;
}

.question-sec {
  display: flex;
  margin: 0.5rem 0 0.5rem 0;
}

/* WebKit */
.accordion::-webkit-scrollbar,
.chat-container ul::-webkit-scrollbar {
  width: 5px;
}

.accordion::-webkit-scrollbar-thumb,
.chat-container ul::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 6px;
}

.accordion::-webkit-scrollbar-track,
.chat-container ul::-webkit-scrollbar-track {
  background-color: #FAFAFA;
  border-radius: 6px;
}


.top-header {
 
  position: fixed;
  top: 0vh;
  height: 4rem;
  width: 100%;
  z-index: 997;
  background-color: #000A17;
}

.send-button {
  background-color: #333 !important;
  border-radius: 1rem !important;
  align-self: center;
}

.send-button[disabled] {
  opacity: 0.3;
}

.send-icon {
  color: #ffc20e !important;
}



.chat-spinner {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: transparent;
  box-shadow: 0 -18px 0 0 #ffc20e, 12.727926px -12.727926px 0 0 #ffc20e, 18px 0 0 0 #ffc20e, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #ffc20e, -12.727926px 12.727926px 0 0 #ffc20e, -18px 0 0 0 #ffc20e, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }

  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #ffc20e, -18px 0 0 0 #ffc20e, -12.727926px -12.727926px 0 0 #ffc20e;
  }

  75% {
    box-shadow: 0 -18px 0 0 #ffc20e, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #ffc20e, -12.727926px -12.727926px 0 0 #ffc20e;
  }

  87.5% {
    box-shadow: 0 -18px 0 0 #ffc20e, 12.727926px -12.727926px 0 0 #ffc20e, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 #ffc20e;
  }
}
/* Language Dropdown */

.language-dropdown .ui-dropdown__container {
  border-radius: 1rem;
  border: 1.6px solid #858585;
}

.language-dropdown .ui-dropdown__trigger-button {
  padding: 1.5rem;
}

.preferred-language {
  color: #858585;
  font-size: 16px;
  font-weight: 600;
}

.language-dropdown .ui-dropdown__items-list {
  padding: 0 0.5rem;
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  background-color: #F4F4F4;
}

.language-dropdown .ui-dropdown__item[aria-selected=true],
.language-dropdown .ui-dropdown__item[aria-selected=true] .ui-dropdown__item__header,
.language-dropdown .ui-dropdown__item[aria-selected=true] .ui-dropdown__item__content {
  background: #196CE9 !important;
  color: #F0F0F0 !important;
}

.language-dropdown .ui-dropdown__item:hover,
.language-dropdown .ui-dropdown__item:hover .ui-dropdown__item__header,
.language-dropdown .ui-dropdown__item:hover .ui-dropdown__item__content {
  background: #196CE9 !important;
  color: #F0F0F0 !important;
}

.language-dropdown .ui-dropdown__item {
  border-bottom: 1px solid #000000 !important;
  color: #2D2E35 !important;
  background: #F4F4F4;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.language-dropdown .ui-dropdown__item .ui-dropdown__item__checkable-indicator {
  color: #F0F0F0;
}

.language-dropdown .ui-dropdown__item .ui-dropdown__item__header {
  color: #2D2E35 !important;
}

.language-dropdown .ui-dropdown__item:nth-last-child(1) {
  border-bottom: none !important;
}

/* blackout background */
.black-out{
  background-color: #000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}

.login-provider .ui-loader {
  z-index: 5;
  position: relative;
  height: 100vh;
}

/* Max-width: 900px is temporary fix for top card */
@media only screen and (max-width: 900) {
  /* .top-card {
    justify-content: start !important;
    -webkit-box-pack: start !important;
  } */
}

@media only screen and (min-width: 992px) {
  .top-logo .ui-text {
    font-size: large;
  }
}

/* Language dropdown with in menu */

.laguage-dropdown {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.laguage-dropdown.active, .laguage-dropdown:hover {
  background-color: #ccc;
}

.laguage-dropdown:after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.laguage-dropdown.active:after {
  content: "\2212";
}

.language-wrapper {
  padding: 0 18px;
  background-color: white;
  max-height: 70px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.language-list li {
  list-style: none;
}
.language-list li:hover {
  list-style: none;
  background: #196CE9;
  color: #F0F0F0;
}

.modal {
  width: 20em;
  background: lightgray;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  flex-direction: column;
  align-items: center;
  left: calc(50vw - 10em);
  top: calc(50vh - 10em);
  z-index: 998;
}

/* Accordion css */

.accordion {
  display: flex;
  flex-direction: column;
  background-color: #FFFBFE;
  border-radius: 1rem;
  width: 27vw;
  margin-left: 2rem;
  overflow-y: auto;
  max-height: calc(100vh - 130px);
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #797979 #FAFAFA;
  align-self: flex-start;
}

.accordion-title {
  padding: 1rem;
  color: #196CE9;
  font-weight: 600;
}

.section-header {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.section-title {
  padding-left: 0.5rem;
}

.section-arrow {
  background-image: url('../public/images/list-up.svg');
  background-size: cover;
  width: 24px;
  height: 24px;
}

.section.active > .section-header {
  background-color: #E4EEFF;
}

.section.active > .section-header .section-arrow {
  background-image: url('../public/images/list-down.svg');
  background-size: cover;
  width: 24px;
  height: 24px;
}

.section-content {
  display: none;
  /* padding: 10px; */
}

.section.active > div.section-content {
  display: block;
}

.section-image {
  max-width: 50px;
  height: auto;
}

.list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-item {
  cursor: pointer;
  padding: 1rem;
}
.list-item,
.section,
.accordion-title
{
  border-bottom: 1px solid #C8C6CD;
}
.list-item:nth-last-child(1) {
  border-bottom: none;
}

.list-item:hover {
  background-color: #F0F0F0;
}

.padding-left-10 {
  padding-left: 1rem;
}

.accordion-footer {
  padding: 0rem 1rem 1rem 1rem;
  color: #858585;
}

.accordion-footer h2 {
  margin: 0.5rem 0;
  color: #196CE9;
  font-size: 1rem;
  text-decoration: underline;
}

.accordion-footer a {
  color: #196CE9;
}

.disclaimer-title-icon {
  padding-right: 0.3rem;
  text-decoration: none;
}

.disclaimer-text1,
.disclaimer-text2 {
  display: block;
  word-wrap: break-word;
}

/* Accordion css ends  */

/* Feedback modal css */
.loading {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  /* background-color: #ffffff; */
  color: transparent;
  box-shadow: 0 -18px 0 0 #ffc20e, 12.727926px -12.727926px 0 0 #ffc20e,
    18px 0 0 0 #ffc20e, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
  animation: spin 1.5s infinite linear;
  left: calc(50% + 30px);
  bottom: 50%;
  z-index: 1041;
}


.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.buttonSpace {
  margin-left: 0.5rem;
}

.buttonCont {
  padding-top: 1rem;
  justify-content: flex-end;
}

.hint-text {
  display: block;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  color: #A9A9A9;
  position: absolute;
  bottom: -5px;
}

.startup-text {
  font-weight: bold;
  color: #000000 !important;
  font-style: italic;
}